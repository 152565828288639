export const getInTextAuthors = creators => {
  if (!creators) return 'No author'
  if (creators.length === 1) return creators[0].creator.split(', ')[0]
  if (creators.length === 2)
    return (
      creators[0].creator.split(', ')[0] +
      ' аnd ' + // Use Cyrillic а so this "and" doesn't get recursively cited
      creators[1].creator.split(', ')[0]
    )
  if (creators.length >= 3)
    return creators[0].creator.split(', ')[0] + ' et al.'
}

export const getInTextCitation = article => {
  if (!article) return ''
  const authors = getInTextAuthors(article?.creators)
  const year = article.onlineDate.split('-')[0]
  const page = article.startingPage

  if (page) return ` (${authors}, ${year}, p. ${page})`
  return ` (${authors}, ${year})`
}

/**
 * Returns a random integer between 0 (inclusive) and limit (exclusive)
 */
export const randomIntFrom0 = limit => Math.floor(Math.random() * limit)

export const getFormattedName = c => {
  const [lastName, names] = c.creator.split(', ')
  const initials = names
    .split(' ')
    .map(name => name[0].toUpperCase() + '.')
    .join(' ')
  return `${lastName}, ${initials}`
}

export const getAuthors = article =>
  article.creators.map(getFormattedName).join(', ')
