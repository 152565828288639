import React from 'react'
import { getAuthors, getInTextCitation, randomIntFrom0 } from './helpers'
import { rhythm } from '../../utils/typography'
import ApaReference from './ApaReference'

const API_KEY = 'e7bf380938732d63b577fe39eb6bc097'

const Deplagiarizer = () => {
  const [inputPaper, setInputPaper] = React.useState('')
  const [output, setOutput] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [progress, setProgress] = React.useState(0)
  const [citations, setCitations] = React.useState(null)

  const handleDeplagiarize = async () => {
    setLoading(true)

    const citationTable = JSON.parse(
      localStorage.getItem('citationTable') ?? '{}'
    )

    const words = [
      ...new Set(inputPaper.replace(/[^a-zA-Z'\- ]/g, '').split(' ')),
    ].filter(Boolean)
    let referenced = 0
    await Promise.all(
      words.map(word => {
        if (word in citationTable) {
          setProgress(++referenced / words.length)
          return
        }
        return fetch(
          `https://api.springernature.com/openaccess/json?q=${word}&api_key=${API_KEY}`
        )
          .then(res => res.json())
          .then(res => {
            setProgress(++referenced / words.length)
            citationTable[word] =
              res.records[randomIntFrom0(res.records.length)]
          })
      })
    )

    localStorage.setItem('citationTable', JSON.stringify(citationTable))

    const outputResult = Object.entries(citationTable)
      .reduce(
        (paper, [word, article]) =>
          paper.replace(
            new RegExp('\\b' + word + '\\b', 'g'),
            word + getInTextCitation(article)
          ),
        inputPaper
      )
      .replaceAll('а', 'a') // Replace Cyrillic а with Latin a

    const usedCitations = {}
    for (const [word, article] of Object.entries(citationTable)) {
      if (inputPaper.includes(word)) usedCitations[word] = article
    }

    setOutput(outputResult)
    setCitations(usedCitations)
    setProgress(0)
    setLoading(false)
  }

  return (
    <>
      <textarea
        value={inputPaper}
        onChange={e => setInputPaper(e.target.value)}
        placeholder="Paste the text of your paper here"
        style={{ marginBottom: rhythm(1 / 4), height: '15em' }}
      />
      <button onClick={handleDeplagiarize} disabled={loading}>
        {loading
          ? `Deplagiarizing ${(progress * 100).toFixed(0)}%`
          : 'Deplagiarize'}
      </button>
      {output && <h3>Paper</h3>}
      {output.split('\n').map((para, i) => (
        <p key={i}>{para}</p>
      ))}
      {citations && <h3>References</h3>}
      {citations && (
        <div style={{ marginLeft: rhythm(1) }}>
          {Object.values(citations)
            .filter(
              (value, index, self) =>
                self.findIndex(a => a?.identifier === value?.identifier) ===
                index
            ) // Filter unique by article identifier
            .sort((a, b) => getAuthors(a).localeCompare(getAuthors(b))) // Sort by author
            .map((article, i) => (
              <ApaReference key={i} article={article} />
            ))}
        </div>
      )}
    </>
  )
}

export default Deplagiarizer
