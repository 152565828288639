import React from 'react'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import { rhythm } from '../../utils/typography'
import Typed from '../../components/Typed'
import Comments from '../../components/comments'
import Deplagiarizer from '../../components/Deplagiarizer/Deplagiarizer'

const DeplagiarizerIndex = () => {
  return (
    <Layout>
      <SEO title="Deplagiarizer" />
      <h1>Deplagiarizer</h1>
      <Deplagiarizer />
      <h3>How it Works</h3>
      <Typed ele="p">
        A peer-reviewed, scientific article is found for every word in your
        paper. APA 7 style citations are generated, placed in text and collated
        into a references list. You can then copy and paste your paper with
        in-text citations and references back into your word processor,
        confident that what you have said is also what scientific experts have
        said.
      </Typed>
      <h3>Disclaimer</h3>
      <small>
        While this tool may make your paper immune to accusations of plagiarism,
        it does not guarantee a good mark. Use at your own risk.
      </small>
      <hr style={{ marginTop: rhythm(1) }} />
      <Comments slug="/things/deplagiarizer/" />
    </Layout>
  )
}

export default DeplagiarizerIndex
