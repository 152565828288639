import React from 'react'
import { getAuthors } from './helpers'
import { rhythm } from '../../utils/typography'

const ApaReference = ({ article }) => {
  // Author, A. A., Author, B. B., & Author, C. C. (Year). Title of article. Title of Periodical, volume number(issue number), pages. https://doi.org/xx.xxx/yyyy
  if (!article) return null
  const year = article.onlineDate.split('-')[0]
  const volumeNumber = article.volume && ', ' + article.volume
  const issueNumber = article.number && `(${article.number}), `

  return (
    <p style={{ textIndent: rhythm(-1), marginBottom: rhythm(1 / 8) }}>
      {getAuthors(article)} ({year}) {article.title}.{' '}
      <i>
        {article.publicationName}
        {volumeNumber}
      </i>
      {issueNumber}
      {article.startingPage}. {article.doi}
    </p>
  )
}

export default ApaReference
